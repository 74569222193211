<template>
  <div class="stylized-ckeditor email-content-styling">
    <PerfectScrollbar>
      <ckeditor :editor="editor" v-bind="$attrs" :config="editorConfig" />
    </PerfectScrollbar>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
export default {
  inheritAttrs: false,
  components: {
    ckeditor: CKEditor.component,
    PerfectScrollbar,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '|',
            'insertTable',
            '|',
            'blockQuote',
            '|',
            'undo',
            'redo',
          ],
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused),
::v-deep(.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused)) {
  outline: none;
  border: 0;
  box-shadow: none;
  padding: 24px 32px;
}

::v-deep(.ck.ck-editor) {
  --ck-color-text: var(--color-gray-06);
  --ck-color-list-button-on-text: var(--color-secondary);
  --ck-color-toolbar-border: #e0e0ec;
  --ck-drop-shadow: 6px 20px 36px rgba(6, 18, 55, 0.04);

  .ck-toolbar {
    padding-left: 32px;
    border: 0;
    background: 0;
  }
  .ck-dropdown__panel {
    height: fit-content;
  }
  .ck.ck-icon {
    color: var(--color-gray-05);
  }
  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover,
  .ck.ck-button.ck-on {
    background: var(--color-gray-02);
    .ck.ck-icon {
      color: var(--color-secondary);
    }
  }
  .ck.ck-button_with-text.ck-dropdown__button {
    border: 1px solid var(--color-gray-02);
    border-radius: 4px;
  }
  .ck.ck-dropdown__panel {
    border: 0;
  }
  .ck.ck-editor__top {
    position: fixed;
    width: 100%;
    background: var(--email-background);
    border-bottom: 1px solid var(--common-border-color);
    margin-top: -40px;
  }

  .ck.ck-sticky-panel__content {
    position: static !important;
    box-shadow: none !important;
  }
  .ck.ck-sticky-panel__placeholder {
    height: 0 !important;
  }
}

.stylized-ckeditor,
::v-deep(.ck.ck-reset),
::v-deep(.ck.ck-editor__main) {
  height: 100%;
}

.stylized-ckeditor {
  overflow: hidden;
  margin-top: 40px;
  ::v-deep(.ps) {
    height: 100%;
  }
}
::v-deep(.ck.ck-editor__main>.ck-editor__editable) {
  background: var(--email-background);
}
::v-deep(.ck-editor__editable_inline) {
  min-height: 100%;
  border: 0;
  border-top: 1px solid var(--common-border-color);
}
</style>
