<template>
  <div class="email-create__wrapper">
    <div class="email-create__field">
      <label :for="`field-to-${_.uid}`">To:</label>
      <EmailToMultiselect
        v-model="recipients"
        :options="optionsTo"
        :id="`field-to-${_.uid}`"
      />
    </div>

    <div class="email-create__field">
      <label :for="`field-subject-${_.uid}`">Subject:</label>
      <input
        :id="`field-subject-${_.uid}`"
        type="text"
        v-model="subject"
        maxlength="70"
      />
    </div>

    <StylizedCKEditor v-model="body" />

    <Dropzone
      class="email-create__attached__wrapper"
      @onDrop="selectFileList"
      v-slot="{ hide, show }"
    >
      <p class="email-create__attached__title">Attached files</p>
      <input
        type="file"
        ref="inputFile"
        @change="(event) => selectFileList(event.target.files)"
        hidden
        multiple
      />
      <div class="email-create__attached__list">
        <Tag
          label="Add file(-s)"
          class="email-create__attached__item"
          style="cursor: pointer"
          @click="$refs.inputFile.click()"
          :key="`attached-item-add-file`"
        >
          <template #icon>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'email-create__attached__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 5a1 1 0 10-2 0v5a1 1 0 01-1 1H5a1 1 0 100 2h5a1 1 0 011 1v5a1 1 0 102 0v-5a1 1 0 011-1h5a1 1 0 100-2h-5a1 1 0 01-1-1V5z"/></svg>
          </template>
        </Tag>

        <!-- <PerfectScrollbar> -->
        <DropzoneDraggable
          v-model:items="files"
          @drag-start="show"
          @drag-end="hide"
        >
          <template #item="{ item, index, remove }">
            <Tag
              class="email-create__attached__item"
              :label="item.filename"
              :type="item.type"
              :canClose="true"
              @close="remove(index)"
              borderColor
            />
          </template>
        </DropzoneDraggable>
        <!-- </PerfectScrollbar> -->
      </div>
    </Dropzone>

    <div class="email-create__footer">
      <FveDateTimePickerViewText
        :field="{ name: 'sendAtTime' }"
        ref="sendAtTime"
      >
        <template #default="{ date, validate }">
          <div class="email-create__dtp">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 20a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.445.832l3 2a1 1 0 001.11-1.664L13 11.465V8z"/></svg>
            <transition name="slide-fade" mode="out-in">
              <template v-if="validate !== null">Delay sending</template>
              <template v-else>
                Send at {{ prepareSendAtTimeText(date) }}
              </template>
            </transition>
          </div>
        </template>
      </FveDateTimePickerViewText>

      <!-- <EmailDelaySending v-model="sendAtTime" /> -->
      <div class="email-create__btn-group">
        <button class="btn btn--outline" @click="onSaveDraft(true)">
          Discard
        </button>
        <button class="btn btn--primary" @click="onSend">Send</button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from '@component/Dropzone';
import DropzoneDraggable from '@component/DropzoneDraggable';
// import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import EmailToMultiselect from '@component/Email/EmailToMultiselect';
import StylizedCKEditor from '@component/StylizedCKEditor';
import FveDateTimePickerViewText from '@fve/Element/Date/FveDateTimePickerViewText';
import { merge } from 'lodash';
import $enum from '@enum';
const { EMAIL_FOLDER_NAME } = $enum;
import Tag from '@component/Tag';
// import EmailDelaySending from "@component/Email/EmailDelaySending";
import { communityGetSelected, communityGetList } from '@store/community.store';
// import FveMixinForm from "@fve/Mixin/FveMixinForm";

export default {
  name: 'EmailCreate',
  // mixins: [FveMixinForm],
  components: {
    Tag,
    // EmailDelaySending,
    EmailToMultiselect,
    StylizedCKEditor,
    FveDateTimePickerViewText,
    Dropzone,
    DropzoneDraggable,
    // PerfectScrollbar,
  },
  data() {
    return {
      community: communityGetSelected(),
      communityList: communityGetList(),
      EMAIL_FOLDER_NAME,
      // Multiselect
      recipients: [],
      // Attached files
      files: [],
      subject: '',
      // subject: Date.now(),
      sendAtTime: null,
      body: '',
      sent: false,
      id: this.$route.query.id,
      initData: {},
      // body: String(Date.now()),
    };
  },
  watch: {
    '$route.name': {
      handler: async function (v) {
        // Требования:
        // Пустой черновик не сохраняется
        // При заполнении одного и более полей сохранить черновик
        // Ранее созданный черновик обновляется, если любое из полей было изменено
        // prettier-ignore
        const fieldToMatchSearch = ['subject', 'body', 'recipients', 'sendAtTime'];
        const isContainsFilledField = fieldToMatchSearch.some(
          (key) =>
            (Array.isArray(this[key]) && this[key].length) ||
            (!Array.isArray(this[key]) && this[key])
        );

        if (!this.isDraftExists && isContainsFilledField && !this.sent) {
          this.onSaveDraft();
          return;
        }

        if (this.isDraftExists && !this.sent) {
          const { data: sendAtTime = null } =
            await this.$refs.sendAtTime.fieldSubmit();
          this.sendAtTime = sendAtTime
            ? DateTime(sendAtTime).toISOString()
            : null;

          const isDifferent = fieldToMatchSearch.some(
            (key) => this.initData[key] !== this[key]
          );

          if (isDifferent) {
            this.onSaveDraft();
          }
        }
      },
    },
    '$route.query': {
      handler: async function ({ email: current }, { email: previous }) {
        if (current && previous) {
          await this.onSaveDraft();
          await this.formDataReset();

          this.recipients = [current];
        }
      },
      deep: true,
    },
  },
  computed: {
    optionsTo() {
      // let data = {};
      const contactList = this.communityList.reduce(
        (result, { participants }) => {
          result = merge([...participants], result);
          return result;
        },
        []
      );
      // contactList.forEach((item) => (data[item] = true));
      // return Object.keys(data);
      return contactList.map(({ fullname, mail, id, profilePhoto }) => ({
        id,
        fullname,
        mail,
        profilePhoto: profilePhoto,
        combinedField: fullname !== mail ? fullname + mail : mail,
      }));
    },
    isDraftExists() {
      return !!this.id;
    },
  },
  async mounted() {
    const queryEmail = this.$route.query.email;
    if (queryEmail) {
      this.recipients = this.recipients.concat(queryEmail);
    }

    if (this.id) {
      await RequestManager.Email.getEmailById({ id: this.id }).then((data) => {
        if (data && data.folder === EMAIL_FOLDER_NAME.DRAFT) {
          this.recipients = this.initData.recipients = data.recipients || [];
          this.subject = this.initData.subject = data.subject || '';
          this.body = this.initData.body = data.body || '';
          this.files = this.initData.files = data.files || [];
          this.sendAtTime = this.initData.sendAtTime = data.sendAtTime;
        } else {
          this.$router.push({ name: this.$routeName.EMAIL_CREATE });
          const notify = this.$dialog.notify(
            'Attention',
            'This draft does not exist.'
          );
        }
      });
    }
  },

  methods: {
    prepareSendAtTimeText(date) {
      return DateTime(date).format('MM/DD/YY hh:mm A');
    },
    // formSchema() {
    //   return {
    //     sendAtTime: {
    //       type: DateTime,
    //       default: () => null,
    //       field: { required: false },
    //     },
    //   };
    // },
    async getEnhancedOptionsTo() {
      return await Promise.resolve(this.enhancedOptionsTo);
    },
    async selectFileList(files) {
      const fileList = Array.from(files);

      try {
        for (let i = 0; i < fileList.length; i++) {
          const fileObj = await RequestManager.FileStorage.add({
            file: fileList[i],
          });
          this.files = this.files.concat({
            filename: fileObj.filename,
            bucketname: fileObj.bucketname,
            url: fileObj.url,
          });
        }
      } catch (err) {}

      // this.files = this.files.concat(
      //   Array.from($event.target.files)
      // );
    },
    async formDataCollect(folderName) {
      try {
        
        let sendAtTime = null;
        try {
          const sendAtTimeObj = await this.$refs.sendAtTime.fieldSubmit();
          this.sendAtTime = sendAtTimeObj?.data || null;
          sendAtTime = this.sendAtTime ? DateTime(this.sendAtTime).toISOString() : null;
        } catch (e) {
          sendAtTime = null;
          console.warn(e);
        }

        const formData = {
          id: this.id || undefined,
          recipients: this.recipients || [],
          from: USER.Profile.getEmail(),
          subject: this.subject,
          body: this.body,
          ownerCommunityId: this.community?.id || null,
          // Проверяем наличие ID, потому что когда письмо отправляется из Personal (All Contact), комьюнити не нужно указывать, а как раз у Personal (All Contact) нет ID
          ownerCommunityName: this.community?.id ? this.community?.name : null,
          sendAtTime: sendAtTime,
          seen: true,
          files: this.files,
          folder: sendAtTime && !folderName ? EMAIL_FOLDER_NAME.SENDING : folderName || EMAIL_FOLDER_NAME.SENT,
        };

        return formData;
      } catch (err) {
        console.error(err);
      }
    },

    formDataReset() {
      this.subject = '';
      this.recipients = [];
      this.body = '';
      this.sendAtTime = null;
      this.sent = false;
      this.files = [];
    },

    async onSaveDraft(redirect = false) {
      const data = await this.formDataCollect(EMAIL_FOLDER_NAME.DRAFT);

      try {
        if (this.isDraftExists) {
          RequestManager.Email.updateEmailById({ id: this.id, data });
        } else {
          RequestManager.Email.add(data);
        }

        this.sent = true;

        this.$dialog.notify.warning(
          'Attention',
          'Your letter save to drafts!' +
            (redirect ? ' Now you will be moved to Inbox.' : '')
        );

        if (redirect) {
          this.$router.push({ name: this.$routeName.EMAIL });
        }
      } catch (err) {
        console.warn(err);
      }
    },

    async onSend() {
      const data = await this.formDataCollect();

      // Кейс: Для пользователя, почту которого мы указали ручным вводом, даже если он пользователь сервиса, шеринг файлов НЕ РАБОТАЕТ, потому что мы не знаем ID пользователя

      try {
        RequestManager.Email.send({
          delayedSend: !!data.sendAtTime,
          data,
        });

        const shareUsers = this.recipients.reduce((result, mail) => {
          const recipient = this.optionsTo.find(
            (option) => mail === option.mail
          );
          if (recipient?.id) {
            result.push({ type: 'R', id: recipient.id });
          }
          return result;
        }, []);

        const shareObj = {
          skipAccessValidation: false,
          users: shareUsers,
          communities: [],
        };

        this.files.forEach(({ filename }) => {
          RequestManager.FileStorage.shareFile({
            bucketName: USER.Auth.getUserId(),
            filename,
            shareObj,
          });
          // {
          //   skipAccessValidation: true,
          //   users: [
          //     {
          //       type: "string",
          //       id: "string",
          //       lastName: "string",
          //       login: "string",
          //       firstName: "string",
          //       mail: "string",
          //       profilePhoto: "string",
          //     },
          //   ],
          //   communities: [
          //     {
          //       type: "string",
          //       id: "string",
          //       lastName: "string",
          //       login: "string",
          //       firstName: "string",
          //       mail: "string",
          //       profilePhoto: "string",
          //     },
          //   ],
          // };
        });

        this.sent = true;

        this.$dialog.notify.success(
          'Success',
          'Your letter has been sent! Now you will be moved to Inbox.'
        );

        this.$router.push({ name: this.$routeName.EMAIL });
      } catch (err) {
        console.warn(err);
      }
    },

    // removeAttachedItem(i) {
    //   this.files = this.files.filter((_, index) => index !== i);
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep() {
  .drop-preview {
    margin: 0 4px 8px;
  }

  .dropzone-inner {
    position: relative;
  }

  .smooth-dnd-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    position: relative;
    &.smooth-dnd-shown {
      z-index: 999;
      position: absolute;
      top: 0;
      left: 0;
      width: -webkit-fill-available;
      height: -webkit-fill-available;
    }
  }
}
.email-create__dropzone {
  margin: 24px 32px;
  width: -webkit-fill-available;
  // position: absolute;
}
.email-create__attached__wrapper {
  // display: flex;
  margin: 0 32px 0;
  border-top: 1px solid var(--color-gray-02);
  padding-top: 1rem;
  position: relative;
}

.email-create__attached__title {
  color: var(--color-gray-06);
  margin-bottom: 0.5rem;
}
.email-create__attached__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px -8px;
  align-items: flex-start;
}

.email-create__attached__item {
  margin: 0 4px 8px;
  user-select: none;
}

.email-create__attached__icon {
  @include icon-size(1.25);
  fill: var(--color-secondary-light);
}
.email-create__wrapper {
  background-color: var(--email-background);
  border-top: 1px solid var(--common-border-color);
}

.email-create__footer {
  border-top: 1px solid var(--common-border-color);
  min-height: 94px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 24px 36px;
  margin-top: auto;
  .email-create__attached__wrapper + & {
    margin-top: 1.5rem;
  }
}

.email-create__field {
  padding: 24px 32px;
  display: flex;
  align-items: center;
  // width: 100%;
  + .email-create__field {
    padding-top: 0;
    padding-bottom: 20px;
  }
  label {
    color: var(--color-gray-05);
    padding-right: 10px;
    line-height: 150%;
  }

  > input {
    padding: 0;
    border: 0;
    // color: var(--color-gray-07);
    line-height: 150%;
    flex: 1;
    background: transparent;
    color: var(--color-gray-10);
  }
}

.email-create__btn-group {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 16px;
}
</style>
