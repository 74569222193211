<template>
  <div class="email-to-multiselect etm">
    <!--
      :filterResults="true"
      :minChars="2"
      :resolveOnLoad="false"
      :delay="0"
    -->
    <Multiselect
      v-bind="$attrs"
      mode="tags"
      :close-on-select="false"
      :searchable="true"
      :create-option="isQueryValid"
      track-by="combinedField"
      label="fullname"
      valueProp="mail"
      :caret="false"
      :can-clear="false"
      @search-change="(v) => (query = v)"
      :append-new-option="false"
      class="multiselect-customize"
    >
      <template #option="{ option }">
        <Avatar
          v-if="option.mail !== option.fullname"
          :name="option.fullname"
          :image="option.profilePhoto"
          class="multiselect-option__avatar"
        />
        <p
          class="multiselect-option__name"
          v-html="highlightMatches(option.fullname)"
        ></p>
        <p
          v-if="option.mail !== option.fullname"
          class="multiselect-option__email"
          v-html="highlightMatches(option.mail)"
        ></p>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import Avatar from '@component/Avatar';
export default {
  inheritAttrs: false,
  components: {
    Multiselect,
    Avatar,
  },
  data() {
    return {
      query: '',
    };
  },
  computed: {
    isQueryValid() {
      if (!this.query.trim()) return;
      const re = /\S+@\S+\.\S+/;
      return re.test(this.query);
    },
  },
  methods: {
    highlightMatches(value) {
      let { query } = this;
      let index = value?.indexOf(query);
      if (query && index + 1) {
        return value.split(query).join(`<b>${query}</b>`);
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.email-to-multiselect {
  width: 100%;
}

::v-deep() {
  .multiselect-tags {
    overflow: auto;
    max-height: 4.2rem;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--color-gray-02);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d3d3e0;
      border-radius: var(--ck-border-radius, 2px);
    }
  }
  .multiselect {
    border: 0;
    &.is-active {
      box-shadow: none;
    }
  }
  .multiselect-tag {
    border: 1px solid var(--color-secondary-light);
    background: none;
    color: var(--color-gray-06);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 1rem;
    padding: 4px 16px;
  }
  .multiselect-dropdown {
    border: 1px solid #f8f8fa;
    box-shadow: 6px 20px 36px rgba(6, 18, 55, 0.04);
    max-width: 440px;
    overflow-y: auto;
  }
  .multiselect-option {
    display: grid;
    align-items: center;
    grid-gap: 0 8px;
    padding: 8px 16px;
    &:hover {
      background-color: #e0e0ec;
    }
  }
  .multiselect-option__avatar {
    @include icon-size(2);
    grid-column: 1;
    grid-row: 1 / 3;
  }
  .multiselect-option__name {
    grid-column: 2;
    grid-row: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .multiselect-option__email {
    grid-column: 2;
    grid-row: 2;
    color: var(--color-gray-05);
    font-size: 0.875rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .multiselect-tag-remove {
    padding: 0;
  }

  .multiselect-tag-remove-icon {
    @include icon-size(1.25);
    mask-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='currentColor' d='M17.6569 7.75735C18.0474 7.36682 18.0474 6.73366 17.6569 6.34313C17.2663 5.95261 16.6332 5.95261 16.2426 6.34313L12.7071 9.87867C12.3166 10.2692 11.6834 10.2692 11.2929 9.87867L7.75736 6.34313C7.36684 5.95261 6.73367 5.95261 6.34315 6.34313C5.95262 6.73366 5.95262 7.36682 6.34315 7.75735L9.87868 11.2929C10.2692 11.6834 10.2692 12.3166 9.87868 12.7071L6.34315 16.2426C5.95262 16.6332 5.95262 17.2663 6.34315 17.6568C6.73367 18.0474 7.36683 18.0474 7.75736 17.6568L11.2929 14.1213C11.6834 13.7308 12.3166 13.7308 12.7071 14.1213L16.2426 17.6568C16.6332 18.0474 17.2663 18.0474 17.6569 17.6568C18.0474 17.2663 18.0474 16.6332 17.6569 16.2426L14.1213 12.7071C13.7308 12.3166 13.7308 11.6834 14.1213 11.2929L17.6569 7.75735Z'/%3E%3C/svg%3E%0A");
    background-color: #c4c4c4;
  }
}

.multiselect-customize {
  .theme-dark & {
    --ms-border-color: var(--schedule-event-input-border-color);
    --ms-dropdown-border-color: var(--schedule-event-input-border-color);
    --ms-bg: var(--color-gray-02);
    --ms-dropdown-bg: var(--color-gray-02);
    --ms-option-bg-selected: rgba(86, 107, 214, 0.05);
    --ms-option-bg-pointed: rgba(86, 107, 214, 0.15);
    --ms-option-color-selected: var(--color-secondary);
    --ms-option-color-pointed: var(--color-secondary);
    --ms-option-bg-selected-pointed: rgba(86, 107, 214, 0.2);
    --ms-option-color-selected-pointed: var(--color-secondary);
    --ms-ring-color: rgba(86, 107, 214, 0.15);
    // --ms-dropdown-bg: ;
    ::v-deep() {
      .multiselect-tags-search {
        background: transparent;
      }
      .multiselect-dropdown {
        border-color: var(--common-border-color);
      }
    }
  }
}

::v-deep(.multiselect-group-label) {
  &.is-pointed {
    background: lime;
  }
}
</style>
